import React from "react";
import "./Contact.css";
import Footer from "../Pocetna/Footer/Footer";
import Navbar from "../Navbar/Navbar";
import contactimg from "../../assets/contact-img.webp";
import { SocialIcon } from "react-social-icons";
const Contact = () => {
  return (
    <>
      <Navbar />

      <div className="contact">
        <div className="containercontact">
          <div className="left-column ">
            <h3>Kontakt informacije</h3>
            <h4>Grafik d.s.o.</h4>
            <p>Adresa: 2. Oktobar 2, 75000 Tuzla</p>
            <p>Broj telefona: +387 61 639 631</p>
            <p>E-mail: info@grafik.ba</p>
            <div className="socials-contact">
            <SocialIcon
              url="https://www.instagram.com/grafik.ba"
              target="_blank"
            />

            <SocialIcon
              url="https://www.facebook.com/grafik.ba"
              target="_blank"
            />
            </div>
           
          </div>
          <div className="right-column">
            <img src={contactimg} alt="kontakt-slika" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;

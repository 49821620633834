"use client";
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import ImageGallery from "react-image-grid-gallery";
import "./Autografika.css";
import Navbar from "../Navbar/Navbar";
import LijeviMeni from "../Pocetna/LijeviMeni";
import Footer from "../Pocetna/Footer/Footer";
import "./stirodur.css";

const importAll = (r) => r.keys().map(r);
const stirodurNatpisiImages = importAll(
  require.context("../../assets/ofsetStampa", false, /\.(webp)$/i)
);

const Stampa = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Pročitaj više");

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  const imagesArray = stirodurNatpisiImages.map((image, index) => ({
    alt: `Image${index + 1}'s alt text`,
    src: image,
  }));

  const [isParagraphVisible, setIsParagraphVisible] = useState(false);

  const handleButtonClick = () => {
    setIsParagraphVisible(!isParagraphVisible);
    setButtonText(
      isParagraphVisible ? "Pročitaj više" : "Zatvori dodatni tekst"
    );
  };

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="autografika-main">
            <div className="container">
              <div className="row reverse" id="usluge-navi">
                {/* Left column for */}
                <LijeviMeni />
                {/* Right column for */}
                <div className="col-md-8 bg">
                  <h1>Digitalna I Ofsetna Štampa</h1>{" "}
                  <div className="autografika-opis">
                    <p>
                      Digitalna štampa podrazumjeva direktnu povezanost računara
                      u kojem se obavlja digitalna priprema i štamparske mašine
                      i obuhvata dvije podkategorije. Prva je statička digitalna
                      štampa (computer to press), a druga je dinamička digitalna
                      štampa (computer to print).
                    </p>
                    <div className="vise-button">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleButtonClick}
                      >
                        {buttonText}
                      </button>
                    </div>

                    {isParagraphVisible && (
                      <>
                        <p>
                          Prvi tip štampe praktično predstavlja klasičnu ravnu
                          ofset štampu na mašinama kod kojih je osvjetljivač za
                          formu ctplate, postavljen na cilindru forme tako da se
                          forma osvjetljava i razvija na samoj štamparskoj
                          mašini. Dalji tok štampe je isti kao i kod ofset
                          štampe.
                        </p>

                        <p>
                          Druga podvrsta digitalne štampe je dinamička digitalna
                          štampa (computer to print), kod nje se štamparska
                          forma stvara za svaki radni ciklus. Najčešće je ova
                          štampa bezkontaktna, odnosno kod nje pritisak nije
                          osnova štamparskog procesa koja omogućava prenošenje
                          boje sa forme na materijal za štampu.
                        </p>
                      </>
                    )}
                  </div>
                  <div className="stirodur-grid">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="stirodur-grid-mobile">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="display-text-mobile">
                    <p>
                      Prvi tip štampe praktično predstavlja klasičnu ravnu ofset
                      štampu na mašinama kod kojih je osvjetljivač za formu
                      ctplate, postavljen na cilindru forme tako da se forma
                      osvjetljava i razvija na samoj štamparskoj mašini. Dalji
                      tok štampe je isti kao i kod ofset štampe.
                    </p>

                    <p>
                      Druga podvrsta digitalne štampe je dinamička digitalna
                      štampa (computer to print), kod nje se štamparska forma
                      stvara za svaki radni ciklus. Najčešće je ova štampa
                      bezkontaktna, odnosno kod nje pritisak nije osnova
                      štamparskog procesa koja omogućava prenošenje boje sa
                      forme na materijal za štampu.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default Stampa;

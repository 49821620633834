"use client";
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import ImageGallery from "react-image-grid-gallery";
import "./Autografika.css";
import Navbar from "../Navbar/Navbar";
import LijeviMeni from "../Pocetna/LijeviMeni";
import Footer from "../Pocetna/Footer/Footer";
import "./stirodur.css";

const importAll = (r) => r.keys().map(r);
const stirodurNatpisiImages = importAll(
  require.context("../../assets/ceradeCeradneReklame", false, /\.(webp)$/i)
);

const CeradneReklame = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Pročitaj više");

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  const imagesArray = stirodurNatpisiImages.map((image, index) => ({
    alt: `Image${index + 1}'s alt text`,
    src: image,
  }));

  const [isParagraphVisible, setIsParagraphVisible] = useState(false);

  const handleButtonClick = () => {
    setIsParagraphVisible(!isParagraphVisible);
    setButtonText(
      isParagraphVisible ? "Pročitaj više" : "Zatvori dodatni tekst"
    );
  };

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="autografika-main">
            <div className="container">
              <div className="row reverse" id="usluge-navi">
                {/* Left column for */}
                <LijeviMeni />
                {/* Right column for */}
                <div className="col-md-8 bg">
                  <h1>Ceradne Reklame</h1>{" "}
                  <div className="autografika-opis">
                    <p>
                      Ceradne reklame nisu samo sredstvo promocije, već i snažan
                      način da se privuče pažnja ciljane publike. Sa našom
                      štamparijom, možete očekivati izuzetnu preciznost,
                      živopisne boje i dugotrajnu izdržljivost naših proizvoda.
                    </p>
                    <div className="vise-button">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleButtonClick}
                      >
                        {buttonText}
                      </button>
                    </div>

                    {isParagraphVisible && (
                      <>
                        <p>
                          Bilo da su u pitanju velike spoljašnje reklame,
                          banneri za događaje ili prostirke za izloge, pružamo
                          sveobuhvatna rešenja koja odražavaju vašu jedinstvenu
                          viziju.
                        </p>
                        <p>
                          Naša posvećenost kvalitetu i brza isporuka čine nas
                          pouzdanim partnerom u ostvarivanju vaših reklamnih
                          ciljeva. Obratite nam se s poverenjem i doživite snagu
                          vizuelnog utiska uz naše ceradne reklame. Vaš uspeh je
                          naša misija!
                        </p>
                      </>
                    )}
                  </div>
                  <div className="stirodur-grid">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="stirodur-grid-mobile">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="display-text-mobile">
                    <p>
                      Bilo da su u pitanju velike spoljašnje reklame, banneri za
                      događaje ili prostirke za izloge, pružamo sveobuhvatna
                      rešenja koja odražavaju vašu jedinstvenu viziju.
                    </p>
                    <p>
                      Naša posvećenost kvalitetu i brza isporuka čine nas
                      pouzdanim partnerom u ostvarivanju vaših reklamnih
                      ciljeva. Obratite nam se s poverenjem i doživite snagu
                      vizuelnog utiska uz naše ceradne reklame. Vaš uspeh je
                      naša misija!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default CeradneReklame;

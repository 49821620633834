import React, { useEffect, useState } from "react";
import Pocetna from "./components/Pocetna/Pocetna";
import Loader from "./components/Loader/Loader";

const Main = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <div>
          <Pocetna />
        </div>
      )}
    </div>
  );
};

export default Main;

import React from "react";
import LijeviMeni from "../LijeviMeni";
import "./MoreAboutUs.css";
import Counter from "../../Counter/Counter";
import Navbar from "../../Navbar/Navbar";
import Footer from "../Footer/Footer";
const MoreAboutUs = () => {
  return (
    <>
    <Navbar />
      <div className="more-about-us-main">
        
        <div className="graphic-products more-about">
          <div className="container">
            <div className="row reverse ">
              {/* Left column for categories */}
              <LijeviMeni />
              <div className="counter-main-mobile">
                
                <Counter
                  iconClass="fab fa-instagram fa-3x"
                  target={500}
                  label="Zadovoljnih klijenata"
                />
                <Counter
                  iconClass="fab fa-youtube fa-3x"
                  target={1000}
                  label="Završenih projekata"
                />
                <Counter
                  iconClass="fab fa-facebook fa-3x"
                  target={15}
                  label="Godine uspješnog poslovanja"
                />
              </div>
              {/* Right column for cards */}
              <div className="col-md-8">
                <div className="two-column-wrapper">
                  <div className="more-about-us-column">
                    <div className="more-about-us-content">
                      <h1 className="naslov-o-nama">Grafik štamparija</h1>

                      <p>
                        Mi rješavamo probleme. Pronaći najbolji način kako
                        predstaviti vašu djelatnost je naša briga. Svrha dizajna
                        nije samo estetskog karaktera. Funkcionalan i svrsi
                        shodan dizajn može vam pomoći u prodaji vaših proizvoda,
                        ostvarenju povjerenja sa budućim klijentima i pokazati
                        kvalitet i vrijednos vašeg poslovanja.
                      </p>
                      <p>
                        Širok je spektar usluga koje pružamo u domenu vizuelnih
                        komunikacija, od kreiranja logotipa, izrada propagandnog
                        i marketinškog materijala do oformljavanje vašeg
                        cjelokupnog vizuelnog identiteta. Dopustite da vašim
                        klijentima dizajnom prikažemo ko ste zapravo i
                        predstavimo vas na pravi način. Zadovoljstvo je raditi
                        sa različitim klijentima i susretati se sa mnogobrojnim
                        djelatnostima, što nas podstiće, stvara zadovoljstvo i
                        svaki projekat čini jedinstvenim i uzbudljivim.
                      </p>
                      <p>
                        Provjerite naše dosadašnje radove i uvjerite se u naš
                        potencijal i širok spektar usluga koje vam možemo
                        ponuditi.
                      </p>
                    </div>
                  </div>

                  <h1 className="naslov-o-nama-mobitel">Grafik štamparija</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-main">
          
         
          <Counter
          
            iconClass="Zadovoljnih klijenata"
            target={500}
            label="Zadovoljnih klijenata"
          />
         
        
          <Counter
            iconClass="Završenih projekata"
            target={1000}
            label="Završenih projekata"
          />
          <Counter
            iconClass="Godine uspješnog poslovanja"
            target={15}
            label="Godina uspješnog poslovanja"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MoreAboutUs;

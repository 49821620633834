"use client";
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import ImageGallery from "react-image-grid-gallery";
import "./Autografika.css";
import Navbar from "../Navbar/Navbar";
import LijeviMeni from "../Pocetna/LijeviMeni";
import Footer from "../Pocetna/Footer/Footer";

import "./stirodur.css";

const importAll = (r) => r.keys().map(r);
const stirodurNatpisiImages = importAll(
  require.context("../../assets/stirodurNatpisi", false, /\.(webp)$/i)
);

const StirodurSlova = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Pročitaj više");

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  const imagesArray = stirodurNatpisiImages.map((image, index) => ({
    alt: `Image${index + 1}'s alt text`,
    src: image,
  }));

  const [isParagraphVisible, setIsParagraphVisible] = useState(false);

  const handleButtonClick = () => {
    setIsParagraphVisible(!isParagraphVisible);
    setButtonText(
      isParagraphVisible ? "Pročitaj više" : "Zatvori dodatni tekst"
    );
  };

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="autografika-main">
            <div className="container">
              <div className="row reverse" id="usluge-navi">
                {/* Left column for */}
                <LijeviMeni />
                {/* Right column for */}
                <div className="col-md-8 bg">
                  <h1>3D Stirodur Slova</h1>{" "}
                  <div className="autografika-opis">
                    <p>
                      Stirodur je izolacioni materijal koji je našao primjenu i
                      u izradi trodimenzionalnih reklamnih natpisa i logotipa.
                      Posebnim postupkom CNC obrade, od stirodura izrađujemo i
                      dekorativne lajsne za unutrašnju i spoljašnju dekoraciju
                    </p>
                    <div className="vise-button">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleButtonClick}
                      >
                        {buttonText}
                      </button>
                    </div>

                    {isParagraphVisible && (
                      <>
                        <p>
                          Obzirom da se u izradi koriste visoko kvalitetne boje,
                          stirodur reklame se koriste za spoljašnje i unutrašnje
                          obilježavanje objekata, sajmova, revija itd.
                        </p>

                        <p>
                          Reklamna 3D slova i logotipi od stirodura, se izrađuju
                          u najraznovrsnijim formama i veličinama.
                        </p>

                        <p>
                          Stirodur reklame su vrlo zastupljene kada su u pitanju
                          izrada reklama trodimenzionalnih natpisa . Stiodur kao
                          izolacioni materijal je vrlo zastupljen ali je našao
                          primjenu i u izradi trodimenzionalnih reklamnih
                          natpisa i logotipa. Posebnim postupkom kompjuterske
                          CNC obrade stirodur materijalom dobijaju se razni
                          elementi, raznih oblika logotipa, i natpisa reklamnih
                          materijala . Zastupljenost ovakvog vida oglašavanja je
                          vrlo popularizovana u današnje vrijeme, jer je treća
                          dimenzija zauzela visoku poziciju. Samim tim izdvajaju
                          se reklame kako za spoljašnju tako i za untrašnju
                          dekoraciju, a izdvojićemo 3D stirodur slova koja su
                          laka za izradu, i održavanje.
                        </p>

                        <p>
                          U okviru naše kompanije možete izvesti Vašu reklamu
                          ili natpis od stirodur materijala, vec navdenom
                          kompijuterskom CNC obradom. Sjajan tim dobro obućenih
                          saradnika obezbediće Vam kompletnu uslugu izrade Vaše
                          reklame počev od projektovanja, izrade i montaže Vaše
                          stirodur reklame kojom možete definisati logotip ili
                          natpis Vaše kompanije. Oni će zasigurno svojom
                          ljubaznošću i brzinom obezbediti brzu i kvalitetnu
                          izradu Vasih stirodur reklama, kao i štampe na njima.
                        </p>

                        <p>
                          Cijena izrade ovakvih reklama je vrlo dostupna i
                          povoljna, dok je kvalitet samih reklama na zavidnom
                          nivou. Obzirom da se u izradi koriste visoko
                          kvalitetne postojane boje, stirodur reklame se koriste
                          za sve vidove unutrašnje i spoljašnje dekoracije,
                          obilježavanje objekata, sajmova, revija, i svega
                          drugog slicnog. One takodje mogu biti istaknuta i kao
                          privremena rješenja za buduće svetleće reklame. U
                          zavisnosti od Vaše želje štampa se radi u punom, ili
                          crno bijelom printu na najsavremenijim digitalnim
                          mašinama.
                        </p>
                        <p>
                          Naša štampa je otporna na sva abrazivna sredstva za
                          čišćenje i na sve vremenske prilike i neprilike.
                          Stirodur reklame se izrađuju u najraznovrsnijim
                          formama i veličinama. Kod nas možete birati dimeziju
                          željenih slova, njenih fontova, i sirinu njihove treće
                          dimenzije koja će bitno uticati na efekat same reklame
                          ili natpisa Vaše kompanije.
                        </p>
                        <p>
                          Rasvjeta je takođe bitan element ovakvih reklama, i
                          ima jednu od značajnih uloga koji doprinosi efektu
                          ovakvog vida reklamiranja. One mogu biti osvjetljene
                          frontalnim svjetlom, pozadinskim svjetlom, ili njihova
                          rasvjeta može biti kombinovana. Svojim pravim izborom
                          saradnika u izradi Vaše reklame doprinijećete
                          kvalitetnoj izradi Vaše reklame, ali isto tako i
                          njenom atraktivnom izgledu jer ćete kod nas dobiti
                          savremen dizajn logotipa ili natpisa Vaše kompanije.
                          Vaša je odluka, na nama je da Vas uvjerimo u kvalitet.
                        </p>
                      </>
                    )}
                  </div>
                  <div className="stirodur-grid">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="stirodur-grid-mobile">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="display-text-mobile">
                    <p>
                      Obzirom da se u izradi koriste visoko kvalitetne boje,
                      stirodur reklame se koriste za spoljašnje i unutrašnje
                      obilježavanje objekata, sajmova, revija itd.
                    </p>

                    <p>
                      Reklamna 3D slova i logotipi od stirodura, se izrađuju u
                      najraznovrsnijim formama i veličinama.
                    </p>

                    <p>
                      Stirodur reklame su vrlo zastupljene kada su u pitanju
                      izrada reklama trodimenzionalnih natpisa . Stiodur kao
                      izolacioni materijal je vrlo zastupljen ali je našao
                      primjenu i u izradi trodimenzionalnih reklamnih natpisa i
                      logotipa. Posebnim postupkom kompjuterske CNC obrade
                      stirodur materijalom dobijaju se razni elementi, raznih
                      oblika logotipa, i natpisa reklamnih materijala .
                      Zastupljenost ovakvog vida oglašavanja je vrlo
                      popularizovana u današnje vrijeme, jer je treća dimenzija
                      zauzela visoku poziciju. Samim tim izdvajaju se reklame
                      kako za spoljašnju tako i za untrašnju dekoraciju, a
                      izdvojićemo 3D stirodur slova koja su laka za izradu, i
                      održavanje.
                    </p>

                    <p>
                      U okviru naše kompanije možete izvesti Vašu reklamu ili
                      natpis od stirodur materijala, vec navdenom kompijuterskom
                      CNC obradom. Sjajan tim dobro obućenih saradnika
                      obezbediće Vam kompletnu uslugu izrade Vaše reklame počev
                      od projektovanja, izrade i montaže Vaše stirodur reklame
                      kojom možete definisati logotip ili natpis Vaše kompanije.
                      Oni će zasigurno svojom ljubaznošću i brzinom obezbediti
                      brzu i kvalitetnu izradu Vasih stirodur reklama, kao i
                      štampe na njima.
                    </p>

                    <p>
                      Cijena izrade ovakvih reklama je vrlo dostupna i povoljna,
                      dok je kvalitet samih reklama na zavidnom nivou. Obzirom
                      da se u izradi koriste visoko kvalitetne postojane boje,
                      stirodur reklame se koriste za sve vidove unutrašnje i
                      spoljašnje dekoracije, obilježavanje objekata, sajmova,
                      revija, i svega drugog slicnog. One takodje mogu biti
                      istaknuta i kao privremena rješenja za buduće svetleće
                      reklame. U zavisnosti od Vaše želje štampa se radi u
                      punom, ili crno bijelom printu na najsavremenijim
                      digitalnim mašinama.
                    </p>
                    <p>
                      Naša štampa je otporna na sva abrazivna sredstva za
                      čišćenje i na sve vremenske prilike i neprilike. Stirodur
                      reklame se izrađuju u najraznovrsnijim formama i
                      veličinama. Kod nas možete birati dimeziju željenih slova,
                      njenih fontova, i sirinu njihove treće dimenzije koja će
                      bitno uticati na efekat same reklame ili natpisa Vaše
                      kompanije.
                    </p>
                    <p>
                      Rasvjeta je takođe bitan element ovakvih reklama, i ima
                      jednu od značajnih uloga koji doprinosi efektu ovakvog
                      vida reklamiranja. One mogu biti osvjetljene frontalnim
                      svjetlom, pozadinskim svjetlom, ili njihova rasvjeta može
                      biti kombinovana. Svojim pravim izborom saradnika u izradi
                      Vaše reklame doprinijećete kvalitetnoj izradi Vaše
                      reklame, ali isto tako i njenom atraktivnom izgledu jer
                      ćete kod nas dobiti savremen dizajn logotipa ili natpisa
                      Vaše kompanije. Vaša je odluka, na nama je da Vas uvjerimo
                      u kvalitet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default StirodurSlova;

import React, { useEffect, useState, useRef } from 'react';
import './Counter.css';

const Counter = ({ iconClass, target, label }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);

  useEffect(() => {
    const increment = target / 50;
    let intervalId;
    let observer;

    const updateCounter = () => {
      if (count < target) {
        setCount((prevCount) => Math.ceil(prevCount + increment));
      } else {
        setCount(`${target}+`);
        clearInterval(intervalId);
      }
    };

    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting) {
        intervalId = setInterval(updateCounter, 50);
        observer.disconnect(); 
      }
    };

    observer = new IntersectionObserver(handleIntersect, { threshold: 0.5 });
    observer.observe(counterRef.current);

    
    return () => {
      clearInterval(intervalId);
      if (observer) observer.disconnect();
    };
  }, [count, target]);

  return (
    <div ref={counterRef} className="counter-container">
      <i className={iconClass}></i>
      <div className="counter">{count}</div>
      <span>{label}</span>
    </div>
  );
};

export default Counter;

"use client";
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import ImageGallery from "react-image-grid-gallery";
import "./Autografika.css";
import Navbar from "../Navbar/Navbar";
import LijeviMeni from "../Pocetna/LijeviMeni";
import Footer from "../Pocetna/Footer/Footer";
import "./stirodur.css";

const importAll = (r) => r.keys().map(r);
const stirodurNatpisiImages = importAll(
  require.context("../../assets/prozorskaGrafika", false, /\.(webp)$/i)
);

const ProzorskaGrafika = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Pročitaj više");

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  const imagesArray = stirodurNatpisiImages.map((image, index) => ({
    alt: `Image${index + 1}'s alt text`,
    src: image,
  }));

  const [isParagraphVisible, setIsParagraphVisible] = useState(false);

  const handleButtonClick = () => {
    setIsParagraphVisible(!isParagraphVisible);
    setButtonText(
      isParagraphVisible ? "Pročitaj više" : "Zatvori dodatni tekst"
    );
  };

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="autografika-main">
            <div className="container">
              <div className="row reverse" id="usluge-navi">
                {/* Left column for */}
                <LijeviMeni />
                {/* Right column for */}
                <div className="col-md-8 bg">
                  <h1>Prozorska Grafika</h1>{" "}
                  <div className="autografika-opis">
                    <p>
                      Prozorske naljepnice su kreirane od vodo-otpornih
                      materijala i idealne su za namjensku izradu reklama što
                      zbog svog jednostavnog postavljanja tako i zbog
                      jednostavnog uklanjanja istih.
                    </p>
                    <div className="vise-button">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleButtonClick}
                      >
                        {buttonText}
                      </button>
                    </div>

                    {isParagraphVisible && (
                      <>
                        <p>
                          tavite svoju poruku gdje kupci najmanje očekuju. Na
                          prozor, vrata automobila , ili čak na pod vašeg
                          poslovnog prostora. Precizan ispis daje vam vizuale
                          koji privlače poglede na ambalažu, ciljani proizvod
                          ili na vaše poslovne objekate. U bilo kojoj količini,
                          bilo kojih veličina, te u bilo kojem obliku izradit
                          ćemo naljepnice te prilagodit ih vašim potrebama i
                          željama.
                        </p>

                        <p>
                          Promovišite svoju firmu, organizaciju, proizvod ili
                          događaj s efektivnim prozorskim naljepnicama iz Grafik
                          ponude. Prozorske naljepnice vam omogućavaju da
                          pretvorite vaše izloge u dinamičku cjelodnevnu
                          reklamu.. To je jednostavan način da vašim
                          potencijalnim klijentima prikažete svoje usluge, radno
                          vrijeme, logo i druge bitne informacije.
                        </p>
                      </>
                    )}
                  </div>
                  <div className="stirodur-grid">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="stirodur-grid-mobile">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="display-text-mobile">
                    <p>
                      tavite svoju poruku gdje kupci najmanje očekuju. Na
                      prozor, vrata automobila , ili čak na pod vašeg poslovnog
                      prostora. Precizan ispis daje vam vizuale koji privlače
                      poglede na ambalažu, ciljani proizvod ili na vaše poslovne
                      objekate. U bilo kojoj količini, bilo kojih veličina, te u
                      bilo kojem obliku izradit ćemo naljepnice te prilagodit ih
                      vašim potrebama i željama.
                    </p>

                    <p>
                      Promovišite svoju firmu, organizaciju, proizvod ili
                      događaj s efektivnim prozorskim naljepnicama iz Grafik
                      ponude. Prozorske naljepnice vam omogućavaju da pretvorite
                      vaše izloge u dinamičku cjelodnevnu reklamu.. To je
                      jednostavan način da vašim potencijalnim klijentima
                      prikažete svoje usluge, radno vrijeme, logo i druge bitne
                      informacije.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default ProzorskaGrafika;

"use client";
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import ImageGallery from "react-image-grid-gallery";
import "./Autografika.css";
import Navbar from "../Navbar/Navbar";
import LijeviMeni from "../Pocetna/LijeviMeni";
import Footer from "../Pocetna/Footer/Footer";
import "./stirodur.css";
const importAll = (r) => r.keys().map(r);
const stirodurNatpisiImages = importAll(
  require.context("../../assets/autografika", false, /\.(webp)$/i)
);

const Autografika = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Pročitaj više");

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  const imagesArray = stirodurNatpisiImages.map((image, index) => ({
    alt: `Image${index + 1}'s alt text`,
    src: image,
  }));

  const [isParagraphVisible, setIsParagraphVisible] = useState(false);

  const handleButtonClick = () => {
    setIsParagraphVisible(!isParagraphVisible);
    setButtonText(
      isParagraphVisible ? "Pročitaj više" : "Zatvori dodatni tekst"
    );
  };

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="autografika-main">
            <div className="container">
              <div className="row reverse" id="usluge-navi">
                {/* Left column for */}
                <LijeviMeni />
                {/* Right column for */}
                <div className="col-md-8 bg">
                  <h1>Auto Grafika</h1>{" "}
                  <div className="autografika-opis">
                    <p>
                      Auto grafika i brendiranje vozila predstavljaju direktnu
                      aplikaciju pvc folije ili naljepnica na vanjsku površinu
                      auta ili drugih objekata koristeći specijalno dizajnirane
                      i razvijene pvc materijale.
                    </p>
                    <div className="vise-button">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleButtonClick}
                      >
                        {buttonText}
                      </button>
                    </div>

                    {isParagraphVisible && (
                      <>
                        <p>
                          Mogućnosti za brendiranje vozila su beskonačne. Možete
                          izabrati brendiranje cijele površine auta sa punom
                          štampom ili alternativno istaknuti pojedinačne
                          dijelove vozila, kao što su hauba, krov, polopac za
                          gorivo ili samo pojedinačne dijelove vrata. Folije za
                          autografiku su dostupne u različitim nijansama i
                          materijalima kao što su matt, karbon imitacije,
                          metalik i glos i efekti, a bezbrojne su varijacije
                          dizajna za štampu sve u zavisnosti od prohtjeva
                          klijenta.
                        </p>

                        <p>
                          Digitalna štampa nudi mogućnosti u postizanju
                          individualnih vizuala, logotipa, tipografije i
                          ukrasnih efekata. Dio prošlosti su ograničenja sa par
                          standardnih boja proizvođaća auta ili bacanje enormnih
                          količina novca na farbanje vaših lubimaca u
                          nesvakidašnje boje. Postoji bezbroj vrsta i prednosti
                          aplikacije auto folija na auta.
                        </p>
                        <h4>DIZAJNIRANE FOLIJE</h4>
                        <p>
                          Učinite svoj auto unikatnim. Dizajnirajte vaše auto
                          individualno, baš onako kako Vi želite da bude, kako
                          bi postalo istinski jedinstveno kao što ste i Vi.
                        </p>
                        <h4>ZAŠTITA</h4>
                        <p>
                          Naljepnicama štitite orginalnu fabričku boju od
                          nepovoljnih vremenskih uticaja i manjih ogrebotina.
                        </p>
                        <h4>PODIZANJE VRIJEDNOSTI</h4>
                        <p>
                          Korištenjem parcijalnih naljepnica vrijednost Vašeg
                          auta se može unikatno povečati. Istaknite određene
                          dijelove svog automobila kroz korištenje posebnog
                          dizajna ili specijalnih auto folija. Postignite na
                          vašem autu specijalni efekat korištenjem različitih
                          nijansi folije na određenim dijelovima auta ili
                          presvlačenjem haube u specijalnu karbon foliju.
                        </p>
                        <p>
                          Auto grafika Vam predstavlja svakako najpovoljniju
                          vrstu marketinga. Nećete morati da plaćate enormne
                          sume novca za bilborde već će Vašu reklamu za mnogo
                          manju svotu novca vidjeti veći broj ljudi. Auto
                          grafika je veoma efektan vid reklamiranja jer
                          brendirano vozilo svakako privlaći veću pažnju od neke
                          druge reklame ili plakata. Mi ćemo Vam dati predlog
                          kako da brendirate svo auto. Ukoliko i Vi imate Vaše
                          predloge, otvoreni smo za sve i celokupan posao ćemo
                          obaviti kako Vam se dopada. Auto grafika je nešto čime
                          se bavimo već veoma dugo i zahvaljujući vrhunskom
                          kvalitetu usluga koje pružamo kada je auto grafika u
                          pitanju, a i sve druge delatnosti i zahvaljujući
                          poštovanju svih zadatih rokova postali smo
                          najtraženiji copy centar kada je i auto grafika u
                          pitanju.
                        </p>
                        <p>
                          Svakako je brendiranje vozila, odnosno auto grafika
                          najbolji izbor za Vas i nudi Vam najveći pomak kada je
                          o oglašavanju i reklamiranju riječ. Sigurno da ne
                          postoji firma kojoj je auto grafika strana riječ,
                          svaka vodeća kompanija ima brendirano vozilo.
                          Instalacija auto grafike ne traje dugo ali su zato
                          nalepnice odradjene kod nas dugotrajne. Vrijeme je i
                          za Vas da se priključite ostalima. Ne gubite više Vaše
                          dragocijeno vrijeme već se odlučite za najbolje kada
                          je auto grafika u pitanju, a to smo zasigurno mi!
                        </p>
                      </>
                    )}
                  </div>
                  <div className="stirodur-grid">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={0}
                    />
                  </div>
                  <div className="stirodur-grid-mobile">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={160}
                      gapSize={0}
                    />
                  </div>
                  <div className="display-text-mobile">
                    <p>
                      Mogućnosti za brendiranje vozila su beskonačne. Možete
                      izabrati brendiranje cijele površine auta sa punom štampom
                      ili alternativno istaknuti pojedinačne dijelove vozila,
                      kao što su hauba, krov, polopac za gorivo ili samo
                      pojedinačne dijelove vrata. Folije za autografiku su
                      dostupne u različitim nijansama i materijalima kao što su
                      matt, karbon imitacije, metalik i glos i efekti, a
                      bezbrojne su varijacije dizajna za štampu sve u zavisnosti
                      od prohtjeva klijenta.
                    </p>

                    <p>
                      Digitalna štampa nudi mogućnosti u postizanju
                      individualnih vizuala, logotipa, tipografije i ukrasnih
                      efekata. Dio prošlosti su ograničenja sa par standardnih
                      boja proizvođaća auta ili bacanje enormnih količina novca
                      na farbanje vaših lubimaca u nesvakidašnje boje. Postoji
                      bezbroj vrsta i prednosti aplikacije auto folija na auta.
                    </p>
                    <h4>DIZAJNIRANE FOLIJE</h4>
                    <p>
                      Učinite svoj auto unikatnim. Dizajnirajte vaše auto
                      individualno, baš onako kako Vi želite da bude, kako bi
                      postalo istinski jedinstveno kao što ste i Vi.
                    </p>
                    <h4>ZAŠTITA</h4>
                    <p>
                      Naljepnicama štitite orginalnu fabričku boju od
                      nepovoljnih vremenskih uticaja i manjih ogrebotina.
                    </p>
                    <h4>PODIZANJE VRIJEDNOSTI</h4>
                    <p>
                      Korištenjem parcijalnih naljepnica vrijednost Vašeg auta
                      se može unikatno povečati. Istaknite određene dijelove
                      svog automobila kroz korištenje posebnog dizajna ili
                      specijalnih auto folija. Postignite na vašem autu
                      specijalni efekat korištenjem različitih nijansi folije na
                      određenim dijelovima auta ili presvlačenjem haube u
                      specijalnu karbon foliju.
                    </p>
                    <p>
                      Auto grafika Vam predstavlja svakako najpovoljniju vrstu
                      marketinga. Nećete morati da plaćate enormne sume novca za
                      bilborde već će Vašu reklamu za mnogo manju svotu novca
                      vidjeti veći broj ljudi. Auto grafika je veoma efektan vid
                      reklamiranja jer brendirano vozilo svakako privlaći veću
                      pažnju od neke druge reklame ili plakata. Mi ćemo Vam dati
                      predlog kako da brendirate svo auto. Ukoliko i Vi imate
                      Vaše predloge, otvoreni smo za sve i celokupan posao ćemo
                      obaviti kako Vam se dopada. Auto grafika je nešto čime se
                      bavimo već veoma dugo i zahvaljujući vrhunskom kvalitetu
                      usluga koje pružamo kada je auto grafika u pitanju, a i
                      sve druge delatnosti i zahvaljujući poštovanju svih
                      zadatih rokova postali smo najtraženiji copy centar kada
                      je i auto grafika u pitanju.
                    </p>
                    <p>
                      Svakako je brendiranje vozila, odnosno auto grafika
                      najbolji izbor za Vas i nudi Vam najveći pomak kada je o
                      oglašavanju i reklamiranju riječ. Sigurno da ne postoji
                      firma kojoj je auto grafika strana riječ, svaka vodeća
                      kompanija ima brendirano vozilo. Instalacija auto grafike
                      ne traje dugo ali su zato nalepnice odradjene kod nas
                      dugotrajne. Vrijeme je i za Vas da se priključite
                      ostalima. Ne gubite više Vaše dragocijeno vrijeme već se
                      odlučite za najbolje kada je auto grafika u pitanju, a to
                      smo zasigurno mi!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default Autografika;

"use client";
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import ImageGallery from "react-image-grid-gallery";
import "./Autografika.css";
import Navbar from "../Navbar/Navbar";
import LijeviMeni from "../Pocetna/LijeviMeni";
import Footer from "../Pocetna/Footer/Footer";
import "./stirodur.css";

const importAll = (r) => r.keys().map(r);
const stirodurNatpisiImages = importAll(
  require.context("../../assets/imitacijapjeskarenja", false, /\.(webp)$/i)
);

const ImitacijaPjeskarenja = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Pročitaj više");

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  const imagesArray = stirodurNatpisiImages.map((image, index) => ({
    alt: `Image${index + 1}'s alt text`,
    src: image,
  }));

  const [isParagraphVisible, setIsParagraphVisible] = useState(false);

  const handleButtonClick = () => {
    setIsParagraphVisible(!isParagraphVisible);
    setButtonText(
      isParagraphVisible ? "Pročitaj više" : "Zatvori dodatni tekst"
    );
  };

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="autografika-main">
            <div className="container">
              <div className="row reverse" id="usluge-navi">
                {/* Left column for */}
                <LijeviMeni />
                {/* Right column for */}
                <div className="col-md-8 bg">
                  <h1>Imitacija Pjeskarenja</h1>{" "}
                  <div className="autografika-opis">
                    <p>
                      Imitacija pjeskarenja je tehnika koja se koristi kako bi
                      se postigao izgled pjeskarenog stakla, metala ili drugih
                      površina, bez stvarnog fizičkog pjeskarenja.
                    </p>
                    <div className="vise-button">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleButtonClick}
                      >
                        {buttonText}
                      </button>
                    </div>

                    {isParagraphVisible && (
                      <>
                        <p>
                          Oživite površine svojih predmeta bez stvarnog
                          pjeskarenja! Naša usluga imitacije pjeskarenja koristi
                          inovativne tehnike bojenja i sprejanja kako bi
                          postigla autentičan izgled pjeskarenih materijala. Ova
                          pristupačna alternativa tradicionalnom pjeskarenju
                          omogućava vam da transformišete staklo, metal ili
                          druge površine sa sofisticiranim i teksturiranim
                          izgledom.
                        </p>
                      </>
                    )}
                  </div>
                  <div className="stirodur-grid">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="stirodur-grid-mobile">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="display-text-mobile">
                    <p>
                      Oživite površine svojih predmeta bez stvarnog pjeskarenja!
                      Naša usluga imitacije pjeskarenja koristi inovativne
                      tehnike bojenja i sprejanja kako bi postigla autentičan
                      izgled pjeskarenih materijala. Ova pristupačna alternativa
                      tradicionalnom pjeskarenju omogućava vam da transformišete
                      staklo, metal ili druge površine sa sofisticiranim i
                      teksturiranim izgledom.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default ImitacijaPjeskarenja;

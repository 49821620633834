"use client";
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import ImageGallery from "react-image-grid-gallery";
import "./Autografika.css";
import Navbar from "../Navbar/Navbar";
import LijeviMeni from "../Pocetna/LijeviMeni";
import Footer from "../Pocetna/Footer/Footer";
import "./stirodur.css";

const importAll = (r) => r.keys().map(r);
const stirodurNatpisiImages = importAll(
  require.context("../../assets/billbordi", false, /\.(webp)$/i)
);

const Bilbordi = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Pročitaj više");

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  const imagesArray = stirodurNatpisiImages.map((image, index) => ({
    alt: `Image${index + 1}'s alt text`,
    src: image,
  }));

  const [isParagraphVisible, setIsParagraphVisible] = useState(false);

  const handleButtonClick = () => {
    setIsParagraphVisible(!isParagraphVisible);
    setButtonText(
      isParagraphVisible ? "Pročitaj više" : "Zatvori dodatni tekst"
    );
  };

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="autografika-main">
            <div className="container">
              <div className="row reverse" id="usluge-navi">
                {/* Left column for */}
                <LijeviMeni />
                {/* Right column for */}
                <div className="col-md-8 bg">
                  <h1>Bilbordi</h1>{" "}
                  <div className="autografika-opis">
                    <p>
                      Izrada idejnog rješenja, printanje bilborda i
                      iznajmljivanje reklamnih površina.
                    </p>
                    <div className="vise-button">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleButtonClick}
                      >
                        {buttonText}
                      </button>
                    </div>

                    {isParagraphVisible && (
                      <>
                        <p>
                          Jumbo plakati ili bilbordi su provjeren način outdoor
                          oglašavanja. Prvi put su se pojavili 1860. godine u
                          Sjedinjenim Američkim Državama. Od tada do danas je
                          ostvaren ogroman napredak u oglašavanju, koji je
                          dosegao rang industrijske proizvodnje. Plakati su se
                          nekad ručno izrađivali, a danas je sve kompjuterska
                          obrada, pa tako imamo i trodimenzionalne digitalne
                          grafike. Nužno je naglasiti da se bilbordi najčešće
                          koriste za kampanje kojima se želi postići veća
                          teritorijalna pokrivenost, kao i duža prisutnost
                          poruke. Ova vrsta plakata se postavlja pored
                          magistralnih puteva, cesta, na autobuskim stajalištima
                          kao i na svim slobodnim površinama koje su frekventne.
                        </p>

                        <p>
                          Poenta je da se postigne što veći, upečatljiviji i
                          funkcionalniji reklamni plakat koji će ostati u
                          pamćenju potencijalnog kupca. Marketinški stručnjaci
                          procjenjuju da ništa nije tako efektno kao plakati
                          koji okružuju potrošače, jer je dokazano da je
                          postignuta veća prodaja onih proizvoda koji su
                          konstantno prisutni na plakatima.
                        </p>
                      </>
                    )}
                  </div>
                  <div className="stirodur-grid">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="stirodur-grid-mobile">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="display-text-mobile">
                    <p>
                      Jumbo plakati ili bilbordi su provjeren način outdoor
                      oglašavanja. Prvi put su se pojavili 1860. godine u
                      Sjedinjenim Američkim Državama. Od tada do danas je
                      ostvaren ogroman napredak u oglašavanju, koji je dosegao
                      rang industrijske proizvodnje. Plakati su se nekad ručno
                      izrađivali, a danas je sve kompjuterska obrada, pa tako
                      imamo i trodimenzionalne digitalne grafike. Nužno je
                      naglasiti da se bilbordi najčešće koriste za kampanje
                      kojima se želi postići veća teritorijalna pokrivenost, kao
                      i duža prisutnost poruke. Ova vrsta plakata se postavlja
                      pored magistralnih puteva, cesta, na autobuskim
                      stajalištima kao i na svim slobodnim površinama koje su
                      frekventne.
                    </p>

                    <p>
                      Poenta je da se postigne što veći, upečatljiviji i
                      funkcionalniji reklamni plakat koji će ostati u pamćenju
                      potencijalnog kupca. Marketinški stručnjaci procjenjuju da
                      ništa nije tako efektno kao plakati koji okružuju
                      potrošače, jer je dokazano da je postignuta veća prodaja
                      onih proizvoda koji su konstantno prisutni na plakatima.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default Bilbordi;

"use client";
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import ImageGallery from "react-image-grid-gallery";
import "./Autografika.css";
import Navbar from "../Navbar/Navbar";
import LijeviMeni from "../Pocetna/LijeviMeni";
import Footer from "../Pocetna/Footer/Footer";

import "./stirodur.css";

const importAll = (r) => r.keys().map(r);
const stirodurNatpisiImages = importAll(
  require.context("../../assets/svreklame", false, /\.(webp)$/i)
);

const SvjetleceReklame = () => {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [buttonText, setButtonText] = useState("Pročitaj više");

  useEffect(() => {
    const fakeApiCall = setTimeout(() => {
      setIsContentLoaded(true);
    }, 1000);

    return () => clearTimeout(fakeApiCall);
  }, []);

  const imagesArray = stirodurNatpisiImages.map((image, index) => ({
    alt: `Image${index + 1}'s alt text`,
    src: image,
  }));

  const [isParagraphVisible, setIsParagraphVisible] = useState(false);

  const handleButtonClick = () => {
    setIsParagraphVisible(!isParagraphVisible);
    setButtonText(
      isParagraphVisible ? "Pročitaj više" : "Zatvori dodatni tekst"
    );
  };

  return (
    <div>
      {!isContentLoaded ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="autografika-main">
            <div className="container">
              <div className="row reverse" id="usluge-navi">
                {/* Left column for */}
                <LijeviMeni />
                {/* Right column for */}
                <div className="col-md-8 bg">
                  <h1>Svjetleće Reklame</h1>{" "}
                  <div className="autografika-opis">
                    <p>
                      Svjetleće reklame su danas veoma dobar vid reklamiranja
                      koji ostavlja izuzetan utisak. Njihova prednost je to što
                      su daleko upadljivije od običnih reklamnih panoa i drugih
                      reklamnih proizvoda, pogotovo predveče i noću.
                    </p>
                    <div className="vise-button">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={handleButtonClick}
                      >
                        {buttonText}
                      </button>
                    </div>

                    {isParagraphVisible && (
                      <>
                        <p>
                          Kućišta za naše svjetleće reklame izrađujemo od
                          aluminijumskih profila, alubonda, čelične ili
                          aluminijumske podkonstrukcije, prokroma kao i mnogih
                          drugih materijala. Dijelovi same reklame se izrađuju
                          od materijala koji propuštaju svjetlost poput klirita
                          (pleksiglas), leksana ili baner folija (beklit ili
                          optičko platno).
                        </p>

                        <p>
                          U svijetu svjetlećih reklama novina su led diode u
                          traci ili led moduli kao i LED display. Svjetleće
                          reklame sa led diodama su popularne i atraktivne zbog
                          male potrošnje električne energije s jedne strane, a
                          sa druge strane, moguće ih je programirati. LED
                          display reklame takođe spadaju u jako popularnu
                          kategoriju svjetlećih reklama. Putem teksta (poruke)
                          koju sami sastavljate direktno se obraćate Vašim
                          klijentima, kupcima, potrošačima. Možete ih
                          obavijestiti o trenutnim sniženjima, akcijama, Vašem
                          asortimanu. Budući da možete unijeti bilo kakav tekst
                          mogućnosti su neograničene.
                        </p>
                      </>
                    )}
                  </div>
                  <div className="stirodur-grid">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="stirodur-grid-mobile">
                    <ImageGallery
                      imgArray={imagesArray}
                      columnWidth={230}
                      gapSize={4}
                    />
                  </div>
                  <div className="display-text-mobile">
                    <p>
                      Kućišta za naše svjetleće reklame izrađujemo od
                      aluminijumskih profila, alubonda, čelične ili
                      aluminijumske podkonstrukcije, prokroma kao i mnogih
                      drugih materijala. Dijelovi same reklame se izrađuju od
                      materijala koji propuštaju svjetlost poput klirita
                      (pleksiglas), leksana ili baner folija (beklit ili optičko
                      platno).
                    </p>

                    <p>
                      U svijetu svjetlećih reklama novina su led diode u traci
                      ili led moduli kao i LED display. Svjetleće reklame sa led
                      diodama su popularne i atraktivne zbog male potrošnje
                      električne energije s jedne strane, a sa druge strane,
                      moguće ih je programirati. LED display reklame takođe
                      spadaju u jako popularnu kategoriju svjetlećih reklama.
                      Putem teksta (poruke) koju sami sastavljate direktno se
                      obraćate Vašim klijentima, kupcima, potrošačima. Možete ih
                      obavijestiti o trenutnim sniženjima, akcijama, Vašem
                      asortimanu. Budući da možete unijeti bilo kakav tekst
                      mogućnosti su neograničene.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default SvjetleceReklame;

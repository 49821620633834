import React, { useEffect } from "react";
import "./Pocetna.css";
import Navbar from "../Navbar/Navbar";
import PKatalog from "./PKatalog";
import Footer from "./Footer/Footer";
import OurClients from "../OurClients/OurClients";
import "aos/dist/aos.css";
import Aos from "aos";
import AboutUs from "./AboutUs/AboutUs";

const Pocetna = () => {
  useEffect(() => {
    const initAos = () => {
      if (window.innerWidth > 768) {
        Aos.init({ duration: 1000 });
      }
    };

    initAos();

    window.addEventListener("resize", initAos);

    return () => {
      window.removeEventListener("resize", initAos);
    };
  }, []);

  return (
    <>
      <div className="pocetna-pozadina">
        <div className="pocetna">
          <Navbar />

          <PKatalog />

          <div
            className="about-us"
            data-aos="fade-left"
            data-aos-duration="3000"
          >
            <AboutUs />
          </div>

          <div
            className="klijenti"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <OurClients />
          </div>
          <div
            className="specijal"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <h3>Tražite kvalitetnu uslugu?</h3>
            <h5>Treba vam neko provjeren na tržištu?</h5>
            <div className="vise-o-nama button-container">
              <a href="/kontakt"> Kontaktirajte nas</a>
            </div>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pocetna;

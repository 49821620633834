import React from "react";
import {ScaleLoader } from "react-spinners";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <ScaleLoader color="#335dac" loading margin={4} size={30} />
    </div>
  );
};

export default Loader;

import React, { useState } from "react";
import { ReactComponent as CloseMenu } from "../../assets/ikone/x.svg";
import { ReactComponent as MenuIcon } from "../../assets/ikone/menu.svg";
import line from "../../assets/linija.svg";
import Logo from "../../assets/logo.svg";
import "./Navbar.css";
import brojsvg from "../../assets/ikone/phone-solid.svg";
const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <div className="broj-mobile">
        <p>
          {" "}
          <img src={brojsvg} alt="broj-svg" /> +387 61 639 631
        </p>{" "}
        <p>info@grafik.ba</p>
      </div>
      <div className="nav-main">
        <div className="header">
          <div className="logo-nav">
            <div className="logo-container">
              <a href="/">
                <img src={Logo} alt="logo" />
              </a>
            </div>
            <ul className={click ? "nav-options active" : "nav-options"}>
              <li className="option" onClick={closeMobileMenu}>
                <a href="/">POČETNA</a>
              </li>
              <li className="option" onClick={closeMobileMenu}>
                <a href="/vise-o-nama">O NAMA</a>
              </li>

              <li className="option usluge-nav" onClick={closeMobileMenu}>
                <a href="/auto-grafika">Auto grafika</a>
              </li>
              <li className="option usluge-nav" onClick={closeMobileMenu}>
                <a href="/svjetlece-reklame">Svjetleće reklame</a>
              </li>
              <li className="option usluge-nav" onClick={closeMobileMenu}>
                <a href="/ceradne-reklame">Ceradne reklame</a>
              </li>
              <li className="option usluge-nav" onClick={closeMobileMenu}>
                <a href="/zidna-grafika">Zidna grafika</a>
              </li>
              <li className="option usluge-nav">
                <a href="/3D-stirodur-slova">3D Stirodur slova</a>
              </li>
              <li className="option usluge-nav">
                <a href="/prozorska-grafika">Prozorska grafika</a>
              </li>
              <li className="option usluge-nav">
                <a href="/imitacija-pjeskarenja">Imitacija pjeskarenja</a>
              </li>
              <li className="option usluge-nav">
                <a href="/bilbordi">Bilbordi</a>
              </li>
              <li className="option usluge-nav">
                <a href="/stampa">Štampa</a>
              </li>
              <li className="option usluge-nav">
                <a href="/zastave">Zastave</a>
              </li>
              <li className="option usluge-nav">
                <a href="/zidne-fotografije">Zidne fotografije</a>
              </li>
              <li className="option usluge-nav">
                <a href="/displej-sistemi">Displej sistemi</a>
              </li>
              <li className="option usluge-nav">
                <a href="/promo-materijal">Promo materijal</a>
              </li>
              <li className="option" onClick={closeMobileMenu}>
                <a href="/kontakt">KONTAKT</a>
              </li>
            </ul>
          </div>

          <div className="mobile-menu" onClick={handleClick}>
            {click ? (
              <CloseMenu className="menu-icon" />
            ) : (
              <MenuIcon className="menu-icon" />
            )}
          </div>
        </div>
        <div className="spliter">
          <img src={line} alt="linija" />
        </div>
      </div>
    </>
  );
};

export default Navbar;

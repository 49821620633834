import React from "react";
import "./Graphic.css";
import "./Graphic.scss";
import "./OurClients.css";
import Klijenti0 from  "../../assets/klijenti/Klijenti-00.webp";
import Klijenti1 from "../../assets/klijenti/Klijenti-01.webp";
import Klijenti2 from "../../assets/klijenti/Klijenti-002.webp";
import Klijenti3 from "../../assets/klijenti/Klijenti-03.webp";
import Klijenti4 from "../../assets/klijenti/Klijenti-04.webp";
import Klijenti5 from "../../assets/klijenti/Klijenti-05.webp";
import Klijenti6 from "../../assets/klijenti/Klijenti-06.webp";
import Klijenti7 from "../../assets/klijenti/Klijenti-07.webp";
import Klijenti8 from "../../assets/klijenti/Klijenti-08.webp";
import Klijenti9 from "../../assets/klijenti/Klijenti-09.webp";
import Klijenti10 from "../../assets/klijenti/Klijenti-10.webp";
import Klijenti11 from "../../assets/klijenti/Klijenti-11.webp";
import Klijenti12 from "../../assets/klijenti/Klijenti-12.webp";
import Klijenti13 from "../../assets/klijenti/Klijenti-13.webp";
const OurClients = () => {
  return (
    <div className="our-cover">
      <div className="our-main">
        <h1>Naši klijenti</h1>
        <hr
          className="separator"
          style={{
            width: "40%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "100px",
          }}
        />
        <div className="graphic-logo">
          <ul className="logogrid">
            <li className="logogrid__item">
              <img src={Klijenti0} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti1} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti2} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti3} className="logogrid__img" alt="dezenlogo" />
            </li>
          </ul>
          <ul className="logogrid">
            <li className="logogrid__item">
              <img src={Klijenti4} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti5} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti6} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti7} className="logogrid__img" alt="dezenlogo" />
            </li>
          </ul>
          <ul className="logogrid">
            <li className="logogrid__item">
              <img src={Klijenti8} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti9} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti10} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti11} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti12} className="logogrid__img" alt="dezenlogo" />
            </li>
            <li className="logogrid__item">
              <img src={Klijenti13} className="logogrid__img" alt="dezenlogo" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OurClients;

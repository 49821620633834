import React from "react";
import "./AboutUs.css";
import paleta from "../../../assets/paleta-boja.webp";

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="two-column-wrapper">
        <div className="about-us-column">
          <div className="about-us-content">
            <h1 className="naslov-o-nama">O nama</h1>

            <p>
              Pronaći najbolji način kako predstaviti vašu djelatnost je naša
              briga. Svrha dizajna nije samo estetskog karaktera. Funkcionalan i
              svrsi shodan dizajn može vam pomoći u prodaji vaših proizvoda,
              ostvarenju povjerenja sa budućim klijentima i pokazati kvalitet i
              vrijednost vašeg poslovanja. Dopustite da vašim klijentima
              dizajnom prikažemo ko ste zapravo i predstavimo vas na pravi
              način. Provjerite naše dosadašnje radove i uvjerite se u naš
              potencijal i širok spektar usluga koje vam možemo ponuditi.
            </p>
          </div>
          <div className="vise-o-nama button-container">
            <a href="/vise-o-nama"> Više o nama</a>
          </div>
        </div>

        <div className="image-column">
          <img src={paleta} alt="test" className="responsive-image" />
        </div>
        <h1 className="naslov-o-nama-mobitel">O nama</h1>
      </div>
    </div>
  );
};

export default AboutUs;

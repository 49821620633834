import React from "react";
import "./PKatalog.css";
import stampa from "../../assets/ofsetStampa/stampa (2).webp";
import cerade from "../../assets/cerade-cover.webp";
import autografika from "../../assets/autografika/autoGrafika (41).webp";
import zidnagrafika from "../../assets/zidnagrafika-cover.webp";
import prozorgrafika from "../../assets/prozorskaGrafika/izlozi (22).webp";
import stirodurslova from "../../assets/stirodurNatpisi/stirodur 3D (22).webp";
import displejsistemi from "../../assets/dsistemi/displejsistemi (16).webp";
import promomaterijal from "../../assets/promotivniMaterijal/Promo materijal (16).webp";
import bilbord from "../../assets/billbordi/bilbordi (9).webp";
import svjreklame from "../../assets/svreklame/sReklame (3).webp";
import zastave from "../../assets/grafikaZastava/zastavecover.webp";
import zidnefoto from "../../assets/zidneFotografije/zidneFotografije (1).webp";
import imitacijafoto from "../../assets/imitacijapjeskarenja/imitacijapjeskarenja (12).webp";
import LijeviMeni from "./LijeviMeni";
import { useNavigate } from "react-router-dom";
const PKatalog = () => {
  const navigate = useNavigate();

  const navigateAutoGrafika = () => {
    navigate("/auto-grafika");
  };

  const navigateZastave = () => {
    navigate("/zastave");
  };

  const navigateZidneSlike = () => {
    navigate("/zidne-fotografije");
  };

  const navigateStampa = () => {
    navigate("/stampa");
  };

  const navigateZidna = () => {
    navigate("/zidna-grafika");
  };

  const navigateProzorska = () => {
    navigate("/prozorska-grafika");
  };

  const navigate3d = () => {
    navigate("/3d-stirodur-slova");
  };

  const navigateDisplej = () => {
    navigate("/displej-sistemi");
  };

  const navigatePromo = () => {
    navigate("/promo-materijal");
  };

  const navigateBilbordi = () => {
    navigate("/bilbordi");
  };

  const navigateSvjetleceReklame = () => {
    navigate("/svjetlece-reklame");
  };

  const navigateCeradneReklame = () => {
    navigate("/ceradne-reklame");
  };

  const navigateImitacija = () => {
    navigate("/imitacija-pjeskarenja");
  };

  return (
    <div className="graphic-products">
      <div className="container">
        <div className="row">
          {/* Left column for categories */}
          <LijeviMeni />

          {/* Right column for cards */}
          <div className="col-md-8">
            <div className="offers-cards">
              <div data-aos="fade-up" className="card-container">
                <div className="card">
                  <img
                    onClick={navigateAutoGrafika}
                    src={autografika}
                    alt="autografika"
                  />
                  <div className="naslov">
                    <h3>Auto grafika</h3>
                  </div>
                </div>

                <div className="card">
                  <img
                    onClick={navigateSvjetleceReklame}
                    src={svjreklame}
                    alt="svjetlece-reklame"
                  />
                  <div className="naslov">
                    <h3>Svjetleće reklame</h3>
                  </div>
                </div>
                <div className="card">
                  <img
                    onClick={navigateCeradneReklame}
                    src={cerade}
                    alt="cerade"
                  />
                  <div className="naslov">
                    <h3>Ceradne reklame</h3>
                  </div>
                </div>

                <div className="card">
                  <img
                    onClick={navigateZidna}
                    src={zidnagrafika}
                    alt="zidnagrafika"
                  />
                  <div className="naslov">
                    <h3>Zidna grafika</h3>
                  </div>
                </div>

                <div className="card">
                  <img onClick={navigate3d} src={stirodurslova} alt="booking" />
                  <div className="naslov">
                    <h3>3D Stirodur slova</h3>
                  </div>
                </div>

                <div className="card">
                  <img
                    onClick={navigateProzorska}
                    src={prozorgrafika}
                    alt="prozorska-grafika"
                  />
                  <div className="naslov">
                    <h3>Prozorska grafika</h3>
                  </div>
                </div>

                <div className="card">
                  <img
                    onClick={navigateImitacija}
                    src={imitacijafoto}
                    alt="imitacijafoto"
                  />
                  <div className="naslov">
                    <h3>Imitacija Pjeskarenja</h3>
                  </div>
                </div>

                <div className="card">
                  <img onClick={navigateBilbordi} src={bilbord} alt="bilbord" />
                  <div className="naslov">
                    <h3>Bilbordi</h3>
                  </div>
                </div>

                <div className="card">
                  <img onClick={navigateStampa} src={stampa} alt="Štampa" />
                  <div className="naslov">
                    <h3>Štampa</h3>
                  </div>
                </div>

                <div className="card">
                  <img onClick={navigateZastave} src={zastave} alt="Zastave" />
                  <div className="naslov">
                    <h3>Zastave</h3>
                  </div>
                </div>

                <div className="card">
                  <img
                    onClick={navigateZidneSlike}
                    src={zidnefoto}
                    alt="zidnefoto"
                  />
                  <div className="naslov">
                    <h3>Zidne fotografije</h3>
                  </div>
                </div>

                <div className="card">
                  <img
                    onClick={navigateDisplej}
                    src={displejsistemi}
                    alt="displejsistemi"
                  />
                  <div className="naslov">
                    <h3>Displej sistemi</h3>
                  </div>
                </div>
                <div className="card">
                  <img
                    onClick={navigatePromo}
                    src={promomaterijal}
                    alt="promomaterijal"
                  />
                  <div className="naslov">
                    <h3>Promo materijal</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PKatalog;

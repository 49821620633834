import React from "react";
import "./Footer.css";
import grafiklogo from "../../../assets/logo.svg";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <div className="pg-footer">
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-content-column">
            <div className="footer-logo">
              <a className="footer-logo-link" href="/">
                <img src={grafiklogo} alt="bnaic" />
              </a>
            </div>
            <div className="footer-menu"></div>
          </div>
          <div className="footer-content-column">
            <div className="footer-menu">
              <h2 className="footer-menu-name"> Grafik d.s.o.</h2>
            </div>
            <div className="footer-menu"></div>
          </div>

          <div className="footer-content-column">
            <div className="footer-call-to-action">
              <h2 className="footer-call-to-action-title">Email</h2>
              <p className="footer-call-to-action-link-wrapper">
                info@grafik.ba
              </p>
            </div>
            <div className="footer-call-to-action">
              <h2
                className="footer-call-to-action-title"
                style={{ marginBottom: "10px" }}
              >
                Broj telefona
              </h2>

              <a
                className="footer-call-to-action-link-wrapper"
                href="tel:+38761639631"
                target="_self"
              >
                061639631
              </a>
            </div>
            <div className="footer-call-to-action">
              <h2 className="footer-call-to-action-title">Adresa</h2>
              <p className="footer-call-to-action-link-wrapper">
                2. Oktobar 2 75000 Donja Tuzla, Bosnia and Herzegovina
              </p>
            </div>
          </div>

          <div className="footer-content-column">
            <div className="footer-call-to-action">
              <h2 className="footer-call-to-action-title">Društvene mreže</h2>
              <div className="footer-call-to-action-link-wrapper">
                <SocialIcon
                  url="https://www.instagram.com/grafik.ba"
                  target="_blank"
                />

                <SocialIcon
                  url="https://www.facebook.com/grafik.ba"
                  target="_blank"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright-text">
              <p>
                
                 Grafik{" "} &copy; {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import { Routes, Route } from "react-router-dom";
import Main from "./Main";
import Autografika from "./components/Usluge/Autografika";
import ZidnaGrafika from "./components/Usluge/ZidnaGrafika";
import Stampa from "./components/Usluge/Stampa";
import ProzorskaGrafika from "./components/Usluge/ProzorskaGrafika";
import StirodurSlova from "./components/Usluge/StirodurSlova";
import DisplejSistemi from "./components/Usluge/DisplejSistemi";
import PromoMaterijal from "./components/Usluge/PromoMaterijal";
import MoreAboutUs from "./components/Pocetna/AboutUs/MoreAboutUs";
import Contact from "./components/Contact/Contact";
import Bilbordi from "./components/Usluge/Bilbordi";
import SvjetleceReklame from "./components/Usluge/SvjetleceReklame";
import CeradneReklame from "./components/Usluge/CeradneReklame";
import Zastave from "./components/Usluge/Zastave";
import ZidneSlike from "./components/Usluge/ZidneSlike";
import ImitacijaPjeskarenja from "./components/Usluge/ImitacijaPjeskarenja";

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
ReactGA.initialize(GA_MEASUREMENT_ID);

const MainRouter = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/auto-grafika" element={<Autografika />} />
      <Route path="/zidna-grafika" element={<ZidnaGrafika />} />
      <Route path="/stampa" element={<Stampa />} />
      <Route path="/zastave" element={<Zastave />} />
      <Route path="/zidne-fotografije" element={<ZidneSlike />} />
      <Route path="/prozorska-grafika" element={<ProzorskaGrafika />} />
      <Route path="/3d-stirodur-slova" element={<StirodurSlova />} />
      <Route path="/displej-sistemi" element={<DisplejSistemi />} />
      <Route path="/promo-materijal" element={<PromoMaterijal />} />
      <Route path="/bilbordi" element={<Bilbordi />} />
      <Route path="/svjetlece-reklame" element={<SvjetleceReklame />} />
      <Route path="/vise-o-nama" element={<MoreAboutUs />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/ceradne-reklame" element={<CeradneReklame />} />
      <Route path="/imitacija-pjeskarenja" element={<ImitacijaPjeskarenja />} />
    </Routes>
  );
};

export default MainRouter;

import React from "react";
import carsvg from "../../assets/ikone/car-solid2.svg";
import windowsvg from "../../assets/ikone/windows.svg";
import wallsvg from "../../assets/ikone/building-solid.svg";

import cubesvg from "../../assets/ikone/cube-solid.svg";
import print from "../../assets/ikone/print.svg";
import displaysvg from "../../assets/ikone/display-solid.svg";
import promosvg from "../../assets/ikone/scroll-solid.svg";

import ceradnesvg from "../../assets/ikone/sheet-plastic-solid.svg";
import bilsvg from "../../assets/ikone/billboard-svgrepo-com.svg";
import svjetlecesvg from "../../assets/ikone/circle-half-stroke-solid.svg";
import flagsvg from "../../assets/ikone/flag-regular.svg";
import zidnaslikasvg from "../../assets/ikone/image-regular.svg";

const LijeviMeni = () => {
  return (
    <div className="col-md-4">
      <div className="category-list">
        <h5>Naše usluge</h5>
        <ul>
          <li>
            <img src={carsvg} alt="car-svg" />{" "}
            <a href="/auto-grafika">Auto grafika</a>{" "}
          </li>
          <li>
            <img src={svjetlecesvg} alt="svjetlece-reklame-svg" />{" "}
            <a href="/svjetlece-reklame">Svjetleće reklame</a>{" "}
          </li>
          <li>
            <img src={ceradnesvg} alt="graf-svg" />{" "}
            <a href="/ceradne-reklame">Ceradne reklame</a>{" "}
          </li>
          <li>
            <img src={wallsvg} alt="wall-svg" />{" "}
            <a href="/zidna-grafika">Zidna grafika</a>{" "}
          </li>

          <li>
            <img src={cubesvg} alt="cube-svg" />{" "}
            <a href="/3D-stirodur-slova">3D Stirodur slova</a>{" "}
          </li>
          <li>
            <img src={windowsvg} alt="win-svg" />{" "}
            <a href="/prozorska-grafika">Prozorska grafika</a>{" "}
          </li>

          <li>
            <img src={zidnaslikasvg} alt="/imitacija-pjeskarenja-svg" />{" "}
            <a href="/imitacija-pjeskarenja">Imitacija pjeskarenja</a>{" "}
          </li>

          <li>
            <img
              src={bilsvg}
              alt="bilbordi-svg"
              style={{
                width: "22px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />{" "}
            <a href="/bilbordi">Bilbordi</a>{" "}
          </li>

          <li>
            <img src={print} alt="stampa-svg" /> <a href="/stampa">Štampa</a>{" "}
          </li>

          <li>
            <img src={flagsvg} alt="zastave-svg" />{" "}
            <a href="/zastave">Zastave</a>{" "}
          </li>

          <li>
            <img src={zidnaslikasvg} alt="zidne-fotografije-svg" />{" "}
            <a href="/zidne-fotografije">Zidne fotografije</a>{" "}
          </li>

          <li>
            <img src={displaysvg} alt="display-svg" />{" "}
            <a href="/displej-sistemi">Displej sistemi</a>{" "}
          </li>
          <li>
            <img src={promosvg} alt="promo-svg" />{" "}
            <a href="/promo-materijal">Promo materijal</a>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LijeviMeni;
